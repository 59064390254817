import { MetaType, PageMapType, gitRepositoryType } from "../../../src/types/SiteInfo.types";

export const Meta: MetaType = {
  siteUrl: "https://zizaina.com",
  siteName: "自在な整骨院はりきゅう院",
  keywords: "整骨院,鍼灸院,骨盤矯正,交通事故,自賠責保険,佐波,整体院",
  description: "『自在な整骨院・はりきゅう院』は、猫背・骨盤・産後矯正などの整体院メニューから、交通事故自賠責保険治療まで専門的に診ます。症状や身体のお悩みについての相談は無料です。",
}

export const PageMap: PageMapType = {
  "top": {
    title: "ホーム",
    linkTo: "/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "about": {
    title: "当院について",
    titleEn: "About",
    linkTo: "/about/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: true,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "first": {
    title: "初めての方へ",
    titleEn: "For beginner",
    linkTo: "/first/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: true,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "shop": {
    title: "店舗一覧",
    titleEn: "Shop",
    linkTo: "/shop/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: true,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "treatment": {
    title: "施術内容",
    titleEn: "Treatment",
    linkTo: "/treatment/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: true,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "autonomicNervousDisorder": {
    title: "施術内容 / 自律神経失調症",
    titleEn: "Treatment",
    linkTo: "/treatment/autonomic_nervous_disorder/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "strainedBack": {
    title: "施術内容 / ぎっくり腰",
    titleEn: "Treatment",
    linkTo: "/treatment/strained-back/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "sprainedNeck": {
    title: "施術内容 / 寝違え",
    titleEn: "Treatment",
    linkTo: "/treatment/sprained-neck/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "frozenShoulders": {
    title: "施術内容 / 四十肩・五十肩",
    titleEn: "Treatment",
    linkTo: "/treatment/frozen-shoulders/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "sportsInjuries": {
    title: "施術内容 / スポーツ外傷",
    titleEn: "Treatment",
    linkTo: "/treatment/sports-injuries/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "stiffShoulder": {
    title: "施術内容 / 肩こり(首、肩の痛み)",
    titleEn: "Treatment",
    linkTo: "/treatment/stiff-shoulder/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "lumbago": {
    title: "施術内容 / 腰痛",
    titleEn: "Treatment",
    linkTo: "/treatment/lumbago/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "headache": {
    title: "施術内容 / 頭痛",
    titleEn: "Treatment",
    linkTo: "/treatment/headache/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "eyeStrain": {
    title: "施術内容 / 眼精疲労",
    titleEn: "Treatment",
    linkTo: "/treatment/eye-strain/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "pelvicCorrection": {
    title: "施術内容 / 骨格矯正(骨盤矯正)",
    titleEn: "Treatment",
    linkTo: "/treatment/pelvic-correction/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "postpartumPelvicCorrection": {
    title: "施術内容 / 骨格矯正(骨盤矯正)",
    titleEn: "Treatment",
    linkTo: "/treatment/postpartum-pelvic-correction/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "hunchBackCorrection": {
    title: "施術内容 / 猫背矯正",
    titleEn: "Treatment",
    linkTo: "/treatment/hunch-back-correction/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "rakutore": {
    title: "施術内容 / 楽トレ",
    titleEn: "Treatment",
    linkTo: "/treatment/rakutore/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "accident": {
    title: "施術内容 / 交通事故治療（自賠責保険）",
    titleEn: "Treatment",
    linkTo: "/treatment/accident/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "tendonitis": {
    title: "施術内容 / 腱鞘炎",
    titleEn: "Treatment",
    linkTo: "/treatment/tendonitis/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "kneePain": {
    title: "施術内容 / ひざ痛",
    titleEn: "Treatment",
    linkTo: "/treatment/knee-pain/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "painInLimbs": {
    title: "施術内容 / 手足の痛み、しびれ",
    titleEn: "Treatment",
    linkTo: "/treatment/pain-in-limbs/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "herniatedDisc": {
    title: "施術内容 / 椎間板ヘルニア",
    titleEn: "Treatment",
    linkTo: "/treatment/herniated-disc/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "sciatica": {
    title: "施術内容 / 坐骨神経痛",
    titleEn: "Treatment",
    linkTo: "/treatment/sciatica/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  //"postpartumWorries": {
  //  title: "施術内容 / 産後の悩み",
  //  titleEn: "Treatment",
  //  linkTo: "/treatment/postpartum-worries/",
  //  isExternal: false,
  //  isDisplayedOnHeader: false,
  //  isDisplayedOnFooter: false,
  //  isDisplayedOnHamburger: false,
  //  isDisplayedOnFooterBottom: false
  //},
  "acupuncture": {
    title: "施術内容 / 鍼灸（はりきゅう）",
    titleEn: "Treatment",
    linkTo: "/treatment/acupuncture/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "beautyAcupuncture": {
    title: "施術内容 / 美容鍼",
    titleEn: "Treatment",
    linkTo: "/treatment/beauty-acupuncture/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "highVoltage": {
    title: "施術内容 / ハイボルテージ",
    titleEn: "Treatment",
    linkTo: "/treatment/high-voltage/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "adjuster": {
    title: "施術内容 / アジャスター",
    titleEn: "Treatment",
    linkTo: "/treatment/adjuster/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "threeDimensionalConductorWave": {
    title: "立体動態波(自律神経調整)",
    titleEn: "Treatment",
    linkTo: "/treatment/three-dimensional-conductor-wave/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  
  "sasaguri": {
    title: "自在な整骨院　篠栗院",
    titleEn: "Shop",
    linkTo: "/shop/sasaguri/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: true
  },
  "noogata": {
    title: "自在な整骨院　直方院",
    titleEn: "Shop",
    linkTo: "/shop/noogata/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: true
  },
  "nobeoka": {
    title: "自在な整骨院　延岡院",
    titleEn: "Shop",
    linkTo: "/shop/nobeoka/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: true
  },
  "shimonoseki": {
    title: "自在な整骨院　下関院",
    titleEn: "Shop",
    linkTo: "/shop/shimonoseki/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: true
  },
  "hofu": {
    title: "自在な整骨院　防府院",
    titleEn: "Shop",
    linkTo: "/shop/hofu/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: true
  },
  "voice": {
    title: "患者様の声",
    titleEn: "Voice",
    linkTo: "/shop/sasaguri/voice/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "news": {
    title: "お知らせ",
    titleEn: "News",
    linkTo: "/news/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "blog": {
    title: "ブログ",
    titleEn: "Blog",
    linkTo: "/shop/sasaguri/blog/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "faq": {
    title: "よくあるご質問",
    titleEn: "Faq",
    linkTo: "/faq/",
    isExternal: false,
    isDisplayedOnHeader: true,
    isDisplayedOnFooter: true,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: false,
    isDisplayedOnShopList: false
  },
  "contact": {
    title: "お問い合わせ",
    titleEn: "Contact",
    linkTo: "/contact/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: true,
    isDisplayedOnFooterBottom: true,
    isDisplayedOnShopList: false
  },
  "privacy": {
    title: "個人情報保護方針",
    titleEn: "Privacy Policy",
    linkTo: "/privacy/",
    isExternal: false,
    isDisplayedOnHeader: false,
    isDisplayedOnFooter: false,
    isDisplayedOnHamburger: false,
    isDisplayedOnFooterBottom: true,
    isDisplayedOnShopList: false
  }

}

export const gitRepository: gitRepositoryType = {
  repositoryUrl: "http://dummy-git.com/git/",
  deployBranch: "main",
  documentRoot: "/var/www/html/",
  targetDirectory: "out/_deploy/",
}

export const RSSFeedInfo = {
  targetPath: "/var/www/html/feed/",
  targetFile: "index.xml",
  rssLimit: 10
}

export const snsIcons:any = {
  facebook: "/images/icons/icon_facebook.svg",
  instagram: "/images/icons/icon_instagram.svg",
  line: "/images/icons/icon_line.svg"
}

export const gtm = {
  gtm: "GTM-P38K453P"
}